/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../../icon/empty'

export * from './project-related-icons'
export const AdyenKlarnaAccountColoredIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-account-colored-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const AdyenKlarnaAccountColoredIconSvg = dynamicHydrate(
	() =>
		import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-account-colored-icon').then(
			(mod) => mod.AdyenKlarnaAccountColoredIconSvg
		),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const AdyenKlarnaPaynowColoredIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-paynow-colored-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const AdyenKlarnaPaynowColoredIconSvg = dynamicHydrate(
	() =>
		import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-paynow-colored-icon').then(
			(mod) => mod.AdyenKlarnaPaynowColoredIconSvg
		),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const AdyenKlarnaColoredIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-colored-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const AdyenKlarnaColoredIconSvg = dynamicHydrate(
	() =>
		import('@/components/ui/atoms/icons/payment_methods/adyen-klarna-colored-icon').then(
			(mod) => mod.AdyenKlarnaColoredIconSvg
		),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
