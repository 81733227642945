/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const LightBlurredArrowLeftSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zM9.6 11.8c-.1.2-.1.5 0 .7l4 4.3c.2.2.5.3.7.1.2-.2.3-.5.1-.7l-3.7-4.1 3.7-4c.2-.2.1-.5-.1-.7-.2-.2-.5-.1-.7.1l-4 4.3z"
			fill="currentColor"
			opacity={0.18}
		/>
	</svg>
))
LightBlurredArrowLeftSvg.displayName = 'LightBlurredArrowLeftSvg'

const LightBlurredArrowLeft = forwardRef((props, ref) => (
	<Icon component={LightBlurredArrowLeftSvg} ref={ref} {...props} />
))
LightBlurredArrowLeft.displayName = 'LightBlurredArrowLeft'

LightBlurredArrowLeft.defaultProps = {
	...Icon.defaultProps,
}
LightBlurredArrowLeft.propTypes = {
	...Icon.propTypes,
}

export default LightBlurredArrowLeft
export { LightBlurredArrowLeftSvg }
